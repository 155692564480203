import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import HeroSmall from "../components/hero--sm"
import ArticleContent from "../components/article-content"

const Article = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    return (
      <div>
        <SEO title={frontmatter.fancyTitle} description={frontmatter.excerpt} image={frontmatter.image} date={frontmatter.date} />
        <HeroSmall extraClass="page--article" heading={frontmatter.fancyTitle ? frontmatter.fancyTitle : frontmatter.title}/>
        <ArticleContent 
          heading={frontmatter.title} 
          fancyIntro={frontmatter.fancyIntro}
          content={html} 
          image={frontmatter.image} 
          date={frontmatter.date} 
          readTime={frontmatter.readTime} 
          slug={markdownRemark.fields.slug} 
          tags={frontmatter.tags}/>
      </div>
    )
}

export default Article

export const ArticleTemplateQuery = graphql`
  query ArticleTemplateQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        fancyTitle
        fancyIntro
        excerpt
        image
        readTime
        tags
      }
    }
  }
`